import {
    Box,
    Flex,
    Avatar,
    HStack,
    Text,
    IconButton,
    Button,
    Menu,
    MenuButton,
    MenuList,
    MenuItem,
    MenuDivider,
    useDisclosure,
    useColorModeValue,
    Stack,
    Link as ChakraLink,
} from '@chakra-ui/react';
import { ColorModeSwitcher } from '../ColorModeSwitcher';
import { HamburgerIcon, CloseIcon, AddIcon } from '@chakra-ui/icons';
import { Link as ReactRouterLink, useLocation } from 'react-router-dom';



const Links = ['', 'Projects']

const NavBarLinks = (props) => {
    const { children } = props;
    const location = useLocation();
    return (

        <ChakraLink as={ReactRouterLink} to={children}>
            <Box
                as="a"
                px={2}
                py={1}
                rounded={'md'}
                _hover={{
                    textDecoration: 'none',
                    bg: useColorModeValue('gray.200', 'gray.700'),
                }}
                href={'#'}
                outline={location.pathname === "/" + children ? "2px solid #3182ce" : "none"}
            >

                {children === "" ? "Home" : children}

            </Box>
        </ChakraLink>


    )
}

export default function NavBar() {
    const { isOpen, onOpen, onClose } = useDisclosure()

    return (
        <>
            <Box bg={useColorModeValue('gray.100', 'gray.900')} px={4}>
                <Flex h={16} alignItems={'center'} justifyContent={'space-between'}>
                    <IconButton
                        size={'md'}
                        icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
                        aria-label={'Open Menu'}
                        display={{ md: 'none' }}
                        onClick={isOpen ? onClose : onOpen}
                    />
                    <HStack spacing={8} alignItems={'center'}>
                        <HStack as={'nav'} spacing={4} display={{ base: 'none', md: 'flex' }}>
                            {Links.map((link) => (
                                <NavBarLinks key={link}>{link}</NavBarLinks>
                            ))}
                        </HStack>

                    </HStack>
                    <Flex alignItems={'center'}>
                        <Menu>
                            <ColorModeSwitcher justifySelf="flex-end" />
                        </Menu>
                    </Flex>
                </Flex>

                {isOpen ? (
                    <Box pb={4} display={{ md: 'none' }} onClick={onClose}>
                        <Stack as={'nav'} spacing={4}>
                            {Links.map((link) => (
                                <NavBarLinks key={link}>{link}</NavBarLinks>
                            ))}
                        </Stack>
                    </Box>
                ) : null}

            </Box>

        </>
    )
}

