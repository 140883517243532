import { Text } from '@chakra-ui/react';
import React, { useState } from 'react'

export default function TimeComponent() {

    let today = new Date();
    let time = today.toLocaleTimeString();

    const [currentTime, setCurrentTime] = useState(time);

    const updateTime = () => {
        today = new Date();
        time = today.toLocaleTimeString();
        setCurrentTime(time);
    }
    setInterval(updateTime, 1000);

    return (
        <Text>{currentTime}</Text>
    )
}
