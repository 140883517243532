import React from 'react';
import {
  ChakraProvider,
  Box,
  Text,
  VStack,
  Code,
  Grid,
  Link as ChakraLink,
  theme,
} from '@chakra-ui/react';
import { Logo } from './Logo';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link as ReactRouterLink
} from 'react-router-dom';
import Home from './pages/Home';
import Projects from './pages/Projects';
import NavBar from './components/NavBar';



function App() {
  return (
    <ChakraProvider theme={theme}>

      <Router>

        <NavBar />


        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/projects" element={<></>} />
          {/* <Projects /> */}
        </Routes>


      </Router>

    </ChakraProvider>
  );
}

export default App;
