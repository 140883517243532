import { Container, Image, Text } from '@chakra-ui/react'
import React from 'react';
import pcImage from '../assets/images/pcImage.jpg';
import TimeComponent from '../components/homeComponents/TimeComponent';

export default function Home() {

  return (
    <Container maxW={"8xl"} marginTop={"1%"}>
      <Text>Home</Text>
      <Image src={pcImage} alt="pcImage" width={"40%"} />
      <TimeComponent />
      
    </Container>
  )
}

